import { SpaceType } from './ConnectGame';

export type aiChosenSquare = {
    xPos: number,
    yPos: number
}

export type aiControls = {
    name: string,
    chooseSquare: (board: SpaceType[][], aiMark: string) => aiChosenSquare | null // Only should return null on error
}

const aiRandom: aiControls = {
    name: "Random",
    chooseSquare: function (board: SpaceType[][], aiMark: string) {
        const squareList = [];

        for (let i = 0; i < board.length; i++) {
            for (let j = 0; j < board[i].length; j++) {
                if (board[i][j].textValue === "Q") {
                    squareList.push({ xPos: j, yPos: i });
                }
            }
        }

        const index = Math.floor(Math.random() * squareList.length);

        return squareList[index];
    }
}

const aiNormal: aiControls = {
    name: "Normal",
    chooseSquare: function (board: SpaceType[][], aiMark: string) {
        // Helper function for scoring
        const getGroupScore = function (spaces: aiChosenSquare[], board: SpaceType[][], aiMark: string) {
            const spaceMarks = spaces.map(space => board[space.yPos][space.xPos].textValue);

            let aiCount = 0;
            let opponentCount = 0;
            for (let i = 0; i < spaceMarks.length; i++) {
                if (spaceMarks[i] === aiMark) {
                    aiCount++;
                } else if (spaceMarks[i] !== "Q") {
                    opponentCount++;
                }
            }

            if (aiCount > 0 && opponentCount > 0) {
                return 0;
            }
            if (aiCount > 0) {
                return 100 * Math.pow(100 * 100, aiCount) + 1;
            }
            if (opponentCount > 0) {
                return Math.pow(100 * 100, opponentCount) + 1;
            }
            return 1;
        }

        // Initialize score card
        let boardScore: number[][] = [];
        for (let i = 0; i < board.length; i++) {
            let scoreArray: number[] = [];
            for (let j = 0; j < board[i].length; j++) {
                scoreArray.push(0);
            }
            boardScore.push(scoreArray);
        }

        // Check every row, column and diagonal
        for (let i = 0; i < board.length; i++) {
            for (let j = 0; j < board[i].length; j++) {
                if (j < board[i].length - 4) {
                    const spaces: aiChosenSquare[] = [{ xPos: j, yPos: i }, { xPos: j + 1, yPos: i }, { xPos: j + 2, yPos: i }, { xPos: j + 3, yPos: i }, { xPos: j + 4, yPos: i }];
                    const score = getGroupScore(spaces, board, aiMark);

                    for (let k = 0; k < 5; k++) {
                        let { xPos, yPos } = spaces[k];
                        if (board[yPos][xPos].textValue === "Q") {
                            boardScore[yPos][xPos] += score;
                        }
                    }
                }

                if (i < board.length - 4) {
                    const spaces: aiChosenSquare[] = [{ xPos: j, yPos: i }, { xPos: j, yPos: i + 1 }, { xPos: j, yPos: i + 2 }, { xPos: j, yPos: i + 3 }, { xPos: j, yPos: i + 4 }];
                    const score = getGroupScore(spaces, board, aiMark);

                    for (let k = 0; k < 5; k++) {
                        let { xPos, yPos } = spaces[k];
                        if (board[yPos][xPos].textValue === "Q") {
                            boardScore[yPos][xPos] += score;
                        }
                    }
                }

                if (i < board.length - 4 && j < board[i].length - 4) {
                    const spaces: aiChosenSquare[] = [{ xPos: j, yPos: i }, { xPos: j + 1, yPos: i + 1 }, { xPos: j + 2, yPos: i + 2 }, { xPos: j + 3, yPos: i + 3 }, { xPos: j + 4, yPos: i + 4 }];
                    const score = getGroupScore(spaces, board, aiMark);

                    for (let k = 0; k < 5; k++) {
                        let { xPos, yPos } = spaces[k];
                        if (board[yPos][xPos].textValue === "Q") {
                            boardScore[yPos][xPos] += score;
                        }
                    }
                }

                if (i >= 4 && j < board[i].length - 4) {
                    const spaces: aiChosenSquare[] = [{ xPos: j, yPos: i }, { xPos: j + 1, yPos: i - 1 }, { xPos: j + 2, yPos: i - 2 }, { xPos: j + 3, yPos: i - 3 }, { xPos: j + 4, yPos: i - 4 }];
                    const score = getGroupScore(spaces, board, aiMark);

                    for (let k = 0; k < 5; k++) {
                        let { xPos, yPos } = spaces[k];
                        if (board[yPos][xPos].textValue === "Q") {
                            boardScore[yPos][xPos] += score;
                        }
                    }
                }
            }
        }

        // Find and return highest score
        console.log(boardScore);
        let maxScore = -1;
        let maxCoords = null;
        for (let i = 0; i < board.length; i++) {
            for (let j = 0; j < board[i].length; j++) {
                if (boardScore[i][j] > maxScore) {
                    maxScore = boardScore[i][j];
                    maxCoords = { xPos: j, yPos: i };
                }
            }
        }
        return maxCoords;
    }
}

export const aiList = [aiRandom, aiNormal];
