import React from 'react';
import { Row, Col } from 'react-bootstrap';

class HomePage extends React.Component {
    render() {
        return (
            <div className="mainPage">
                <h1 className="mainPageTitle">Raymond Thornley</h1>
                <Row className="rowNoMargin">
                    <Col xs={12} sm={{ span: 10, offset: 1 }} lg={{ order: 1, span: 3, offset: 0 }} >
                        <div className="infoSection">
                            <h2 className="infoSectionTitle">About me</h2>
                            <span className="infoSectionLine">Software Developer with over 5 years of full-stack development experience. History of adapting quickly when brought onto a new client or project.</span>
                            <span className="infoSectionLine">Currently 29 years old and living in Indianapolis, Indiana</span>
                            <img className="profilePicture" src={"RaymondThornley.jpg"} alt="" />
                            <a href="https://www.linkedin.com/in/raymond-thornley-481847bb/" className="externalLink">
                                <img src={"LI-In-Bug.png"} className="linkImage" alt="" />View my LinkedIn&trade; profile</a>
                            <a href="https://github.com/RaymondThornley" className="externalLink">
                                <img src={"GitHub-Mark-32px.png"} className="linkImage" alt="" />View my GitHub&trade; profile</a>

                        </div>
                    </Col>

                    <Col xs={12} sm={{ span: 10, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                        <div className="infoSection">
                            <h2 className="infoSectionTitle">Work Experience</h2>
                            <h3 className="infoSectionJobHeader">Software Engineer, Sogeti</h3>
                            <span className="infoSectionJobHeaderDate">March 2021-June 2024</span>
                            <h4 className="infoSectionClientHeader">Insurance Client</h4>
                            <h5 className="infoSectionProjectHeader">Document Management</h5>
                            <ul className="infoSectionList">
                                <li className="infoSectionListItem">Maintained a full-stack application in Angular and Spring Boot Java</li>
                                <li className="infoSectionListItem">Retrieved data from MongoDB as client required</li>
                                <li className="infoSectionListItem">Collaborated with a mixed on-shore and off-shore team in the Scaled Agile Framework</li>
                            </ul>
                            <h5 className="infoSectionProjectHeader">Legacy Application Retirement</h5>
                            <ul className="infoSectionList">
                                <li className="infoSectionListItem">Led the efforts to modify a legacy Visual Basic application</li>
                                <li className="infoSectionListItem">Organized the generation and migration of over 100'000 documents</li>
                                <li className="infoSectionListItem">Created API endpoints in a core Spring Boot/Java application to facilitate the migration</li>
                            </ul>
                            <h5 className="infoSectionProjectHeader">Third Party Data Integration</h5>
                            <ul className="infoSectionList">
                                <li className="infoSectionListItem">Created new services in Java and Spring Boot</li>
                                <li className="infoSectionListItem">Automated system to process data from third party</li>
                                <li className="infoSectionListItem">Integrated with and upgraded older services</li>
                            </ul>
                            <h4 className="infoSectionClientHeader">Professional Accosiation Client</h4>
                            <h5 className="infoSectionProjectHeader">Practice Transitions</h5>
                            <ul className="infoSectionList">
                                <li className="infoSectionListItem">Maintained a full-stack application with one other developer in React TypeScript and C# .Net</li>
                                <li className="infoSectionListItem">Supported the creation of an Angular front-end</li>
                                <li className="infoSectionListItem">Improved SQL stored procedures</li>
                            </ul>
                            <h3 className="infoSectionJobHeader">Jr Software Developer, Infosys</h3>
                            <span className="infoSectionJobHeaderDate">October 2017-Febuary 2020</span>
                            <h4 className="infoSectionClientHeader">Manufactoring Client</h4>
                            <h5 className="infoSectionProjectHeader">Maintinence Checklists Website</h5>
                            <ul className="infoSectionList">
                                <li className="infoSectionListItem">Developed a front-end application in ES6 JavaScript</li>
                                <li className="infoSectionListItem">Utilized JavaScript libraries including React, Redux and Axios</li>
                                <li className="infoSectionListItem">Collaborated with ten coworkers remotely in an Agile environment</li>
                                <li className="infoSectionListItem">Created a testing environment using Enzyme and Jest</li>
                            </ul>
                        </div>
                        <div className="infoSection">
                            <h2 className="infoSectionTitle">Education</h2>
                            <h4 className="infoSectionClientHeader">College</h4>
                            <span className="infoSectionLine">Bachelors of Science in Computer Science</span>
                            <span className="infoSectionLine">Minor in Mathematics</span>
                            <span className="infoSectionLine">University of Minnesota, Twin Cities</span>
                            <h4 className="infoSectionClientHeader">Cetifications</h4>
                            <ul className="infoSectionList">
                                <li className="infoSectionListItem">AWS Cloud Practitioner</li>
                                <li className="infoSectionListItem">Scaled Agile Framework 5</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default HomePage;
