import React from 'react';

class NotFoundPage extends React.Component {
    render(){
        return(
            <div className="notFoundPage">
                <h1 className="notFoundPageText">404: Page not found</h1>
            </div>
        );
    }
}

export default NotFoundPage;
